import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';

import { fetchFreeSlots } from '@/services/fetchFreeSlots';
import { useBookingStore } from '@/store/useBookingStore';
import { usePracticeStore } from '@/store/usePracticeStore';

export const useFreeSlots = () => {
  const { provider, appointmentType, setAppointmentType } = useBookingStore();
  const { freeSlots, setFreeSlots } = usePracticeStore();

  const shouldFetchSlots = useMemo(
    () => Boolean(provider?.id && appointmentType !== null),
    [provider, appointmentType],
  );

  const { isFetching, error, data } = useQuery({
    queryKey: [
      'freeSlots',
      {
        providerId: provider?.id ?? null,
        appointmentType: appointmentType || null,
      },
    ],
    queryFn: () => fetchFreeSlots(provider, appointmentType),
    enabled: shouldFetchSlots,
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const freeSlots = data?.freeSlots ?? null;
    if (freeSlots) {
      setFreeSlots(freeSlots);
    } else {
      setAppointmentType(null, []);
    }
  }, [data?.freeSlots, setFreeSlots, setAppointmentType]);

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error);
    }
  }, [data?.error]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return {
    isFetching,
    freeSlots,
  };
};
