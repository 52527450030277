import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { fetchConnection } from '@/services/fetchConnection';
import { usePracticeStore } from '@/store/usePracticeStore';

export const useConnection = () => {
  const { connection, setConnection } = usePracticeStore();

  const { isFetching, data, error } = useQuery({
    queryKey: ['connection'],
    queryFn: fetchConnection,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data?.connection) {
      setConnection(data.connection);
    }
  }, [data, setConnection]);

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error);
    }
  }, [data?.error]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return {
    isFetching,
    connection,
  };
};
