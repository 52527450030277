import { createDearDocAppointment } from '@/lib/api';
import { slotsAreEqual } from '@/lib/helpers/slots';
import { getLocationIdFromUrl } from '@/lib/helpers/urlParams';
import {
  DearDocAppointment,
  DearDocFreeSlots,
  DearDocProvider,
  DearDocSlot,
} from '@/types/api.types';
import { CustomerData } from '@/types/customer.types';
import { MeetingSlot } from '@/types/meetings.types';

export type CreateAppointmentResponse = {
  error?: string | null;
  confirmed: boolean;
  description?: string;
  data?: unknown;
};

export const createAppointment = async (
  provider: DearDocProvider | null,
  selectedAppointmentType: string | null,
  meeting: MeetingSlot | null,
  freeSlots: DearDocFreeSlots | null,
  customer: CustomerData,
): Promise<CreateAppointmentResponse> => {
  try {
    if (!customer) {
      return {
        error: 'Missing customer data',
        confirmed: false,
      };
    }

    if (!provider) {
      return {
        error: 'Please select a valid provider',
        confirmed: false,
      };
    }

    if (!meeting) {
      return {
        error: 'Missing meeting, please select a meeting slot',
        confirmed: false,
      };
    }

    if (!freeSlots) {
      return {
        error: 'Missing slots, please provide a valid list of slots',
        confirmed: false,
      };
    }

    const meetingDate = meeting.date;
    const originalSlot = freeSlots.slots.find((slot: DearDocSlot) =>
      slotsAreEqual(slot.time, meetingDate),
    );
    if (!originalSlot) {
      return {
        error: 'Please select a valid time slot from the given options',
        confirmed: false,
      };
    }
    const appointmentPayload: DearDocAppointment = {
      patient_name: customer.name!,
      patient_email: customer.email!,
      patient_phone: customer.phone!,
      slot: originalSlot.time,
    };

    if (selectedAppointmentType) {
      appointmentPayload.appointment_type = selectedAppointmentType;
    }

    if (originalSlot.operatory_id) {
      appointmentPayload.operatory_id = originalSlot.operatory_id;
    }

    const locationId = getLocationIdFromUrl();
    if (!locationId) {
      return {
        error: 'Missing or invalid location, please provide a valid ID in the URL',
        confirmed: false,
      };
    }
    const { error } = await createDearDocAppointment(locationId, provider.id, appointmentPayload);

    if (error) {
      console.error(error);
      return {
        error: error.toString() ?? 'Error creating assignment',
        confirmed: false,
      };
    }

    return {
      error: null,
      confirmed: true,
      description: `Your appointment has been confirmed for ${meetingDate.toLocaleString(
        'en-US',
      )} with Dr. ${provider.name}`,
    };
  } catch (e) {
    console.error(e);

    return {
      error: "Sorry, something didn't work trying to create the appointment",
      confirmed: false,
    };
  }
};
