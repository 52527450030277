import { FieldError, UseFormRegister } from 'react-hook-form';

import { CustomerData } from '@/types/customer.types';

import styles from './FormField.module.css';

type FormFieldProps = {
  label: string;
  type: string;
  placeholder: string;
  name: keyof CustomerData;
  register: UseFormRegister<CustomerData>;
  error: FieldError | undefined;
  valueAsNumber?: boolean;
};

export const FormField: React.FC<FormFieldProps> = ({
  label,
  type,
  placeholder,
  name,
  register,
  error,
  valueAsNumber,
}) => (
  <div className={styles.container}>
    <label htmlFor={name} className={styles.label}>
      {label}
    </label>
    <input
      type={type}
      placeholder={placeholder}
      className={styles.input}
      {...register(name, { valueAsNumber })}
    />
    {error && <span className={styles.error}>{error.message}</span>}
  </div>
);
