import { format } from 'date-fns';
import { useState } from 'react';

import { Button } from '@/components/Button';
import { formatTime, slotsAreEqual } from '@/lib/helpers/slots';
import { useBookingStore } from '@/store/useBookingStore';
import { MeetingDay, MeetingSlot } from '@/types/meetings.types';

import styles from './TimeSlotSelector.module.css';

interface TimeSlotSelectorProps {
  selectedDate: Date;
  meetingDay: MeetingDay | null;
}

export const TimeSlotSelector = ({ selectedDate, meetingDay }: TimeSlotSelectorProps) => {
  const { setSlot } = useBookingStore();
  const [selectedSlot, setSelectedSlot] = useState<MeetingSlot | null>(null);

  const handleClick = (slot: MeetingSlot) => {
    const newSlot = selectedSlot?.date && slotsAreEqual(slot.date, selectedSlot.date) ? null : slot;
    setSelectedSlot(newSlot);
    setSlot(newSlot);
  };

  const formattedDate = format(selectedDate, 'PP');

  return (
    <>
      <span className={styles.date}>{formattedDate}</span>

      <div className={styles.slotsGrid}>
        {meetingDay?.slots.map((slot) => {
          const formattedTime = formatTime(slot.date);

          return (
            <Button
              key={`${formattedDate} - ${formattedTime}`}
              className={styles.slot}
              isActive={Boolean(selectedSlot?.date && slotsAreEqual(slot.date, selectedSlot.date))}
              onClick={() => handleClick(slot)}
              data-testid="meeting-slot"
            >
              {formattedTime}
            </Button>
          );
        })}
      </div>
    </>
  );
};
