import { z, ZodType } from 'zod';

import { validateFullName, validatePhone } from '@/lib/helpers/formValidation';
import { CustomerData } from '@/types/customer.types';

export const PatientFormSchema: ZodType<CustomerData> = z.object({
  name: z
    .string({
      required_error: 'Your full name is required',
    })
    .trim()
    .min(4, {
      message: 'First and last name are required',
    })
    .refine((value) => validateFullName(value), {
      message: 'First and last name are required',
    }),
  phone: z
    .string({
      required_error: 'Phone is required',
    })
    .trim()
    .length(10, { message: 'Phone must be 10 digits' })
    .refine((value) => validatePhone(value), {
      message: 'Phone must have only numbers',
    }),
  email: z
    .string({
      required_error: 'Email is required',
    })
    .trim()
    .email({
      message: 'Email is invalid',
    }),
});
