import styles from './SelectorContainer.module.css';

interface SelectorContainerProps {
  text: string;
  children?: React.ReactNode;
}

export const SelectorContainer = ({ text, children }: SelectorContainerProps) => {
  return (
    <div className={styles.selectorContainer}>
      <p className={styles.selectorText}>{text}</p>
      <div className={styles.selectorOptions}>{children}</div>
    </div>
  );
};
