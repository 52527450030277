import { Loader } from '@/components/Loader';
import { useConnection } from '@/hooks/useConnection';
import { useProviders } from '@/hooks/useProviders';
import { hasAllInfoFromQuery } from '@/lib/helpers/urlParams';
import { BookingApp } from '@/modules/BookingApp';
import { BookingLayout } from '@/modules/BookingLayout';

import styles from './BookingPage.module.css';

export const BookingPage = () => {
  const hasQueryParams = hasAllInfoFromQuery();

  const { isFetching: isFetchingConnection } = useConnection();
  const { isFetching: isFetchingProviders } = useProviders();

  if (isFetchingProviders || isFetchingConnection) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <BookingLayout>
      <div className={styles.bookingContainer}>
        {hasQueryParams ? <BookingApp /> : <p>Error: missing name, email, phone or location</p>}
      </div>
    </BookingLayout>
  );
};
