import classNames from 'classnames';

import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg';
import { Button } from '@/components/Button';

import styles from './BackButton.module.css';

type BackButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  onClick: () => void;
};

export const BackButton = ({ className, onClick }: BackButtonProps) => {
  return (
    <Button className={classNames(styles.backBtn, className)} onClick={onClick}>
      <ArrowLeft />
    </Button>
  );
};
