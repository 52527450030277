import { getImageUrlFromPath } from '@/lib/helpers/images';
import { usePracticeStore } from '@/store/usePracticeStore';

import styles from './BookingLayout.module.css';

const DEARDOC_LOGO =
  'https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/DearDoc-logo-dark.png?alt=media&token=24c4aed1-0cc9-46b9-82da-16f9a06c2064';

export const BookingLayout = ({ children }: { children: React.ReactNode }) => {
  const { connection } = usePracticeStore();
  return (
    <main className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.subtitleDesktop}>Schedule your appointment with</span>
          <img
            className={styles.logo}
            src={connection?.image ? getImageUrlFromPath(connection.image) : DEARDOC_LOGO}
          />
          <span className={styles.title}>{connection?.name}</span>
          <span className={styles.subtitle}>Schedule your appointment</span>
        </div>
        {children}
      </div>
    </main>
  );
};
