import { Button } from '@/components/Button';
import { SelectorContainer } from '@/components/SelectorContainer';
import { useBookingStore } from '@/store/useBookingStore';
import { usePracticeStore } from '@/store/usePracticeStore';

import styles from './DoctorSelector.module.css';

export const DoctorSelector = () => {
  const { provider, setProvider } = useBookingStore();
  const { providers } = usePracticeStore();

  const handleClick = (id: string) => {
    const newId = provider?.id === id ? '' : id;
    setProvider(newId, providers);
  };

  return (
    <SelectorContainer text="Please start by selecting a doctor:" data-testid="providers">
      {providers &&
        providers.length > 0 &&
        providers.map(({ id, name, provider_profile_image_url }) => (
          <Button
            key={`doctor-${id}`}
            isActive={id === provider?.id}
            onClick={() => handleClick(id)}
            data-testid="doctor-slot"
            className={styles.btn}
          >
            <img className={styles.providerImg} src={provider_profile_image_url} alt={name} />
            <p className={styles.providerName}>{name}</p>
          </Button>
        ))}
    </SelectorContainer>
  );
};
