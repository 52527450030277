import { useBookingStore } from '@/store/useBookingStore';

import styles from './ConfirmationScreen.module.css';

export const ConfirmationScreen = () => {
  const { appointment } = useBookingStore();
  const providerImg = appointment?.provider?.provider_profile_image_url;
  const providerName = appointment?.provider?.name;

  return (
    <section className={styles.confirmationScreen}>
      <img
        className={providerImg ? styles.confirmationProviderImg : styles.confirmationCheckmark}
        src={providerImg ?? '/checkmark.png'}
        alt={providerName}
      />
      <p className={styles.confirmationTitle}>Appointment created successfully</p>
      <p>{appointment?.description}</p>
      <p>You can continue safely.</p>
    </section>
  );
};
