import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { DearDocFooter } from '@/components/DearDocFooter';
import { Loader } from '@/components/Loader';
import { useConnection } from '@/hooks/useConnection';
import { useGoogleActionsConversion } from '@/hooks/useGoogleActionsConversion';
import { useProviders } from '@/hooks/useProviders';
import { BookingApp } from '@/modules/BookingApp';
import { BookingLayout } from '@/modules/BookingLayout';
import { ConfirmationScreen } from '@/modules/ConfirmationScreen';
import { PatientInformationForm } from '@/modules/PatientInformationForm';
import { createAppointment } from '@/services/createAppointment';
import { useBookingStore } from '@/store/useBookingStore';
import { usePracticeStore } from '@/store/usePracticeStore';
import { CustomerData } from '@/types/customer.types';

import styles from './FullBookingPage.module.css';

export const FullBookingPage = () => {
  const { freeSlots } = usePracticeStore();
  const { provider, appointmentType, slot, clearSelection, onAppointmentCreated } =
    useBookingStore();
  const [showForm, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { handleConversionEvent } = useGoogleActionsConversion();

  const { isFetching: isFetchingConnection } = useConnection();
  const { isFetching: isFetchingProviders } = useProviders();

  const {
    isPending,
    error,
    data: newAppointment,
    mutate,
  } = useMutation({
    mutationFn: (customer: CustomerData) =>
      createAppointment(provider, appointmentType, slot, freeSlots, customer),
  });

  useEffect(() => {
    if (newAppointment?.confirmed) {
      setShowConfirmation(true);
      onAppointmentCreated(newAppointment);
      clearSelection();
      closeFoldOut();
    }
  }, [newAppointment, clearSelection, onAppointmentCreated]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (newAppointment?.error) {
      toast.error(newAppointment?.error.toString());
    }
  }, [newAppointment?.error]);

  const closeFoldOut = () => {
    setTimeout(() => {
      window.parent.postMessage(
        JSON.stringify({ action: 'foldOutClose', data: { type: 'iframe' } }),
        '*',
      );
    }, 7500);
  };

  const showPatientForm = () => {
    setShowForm(true);
  };

  const hidePatientForm = () => {
    setShowForm(false);
  };

  const handleSubmit = async (customer: CustomerData) => {
    if (!customer) {
      console.error('Missing patient info');
      return;
    }
    try {
      await mutate(customer);
      await handleConversionEvent();
    } catch (err) {
      console.error(err);
    }
  };

  if (isPending || isFetchingConnection || isFetchingProviders) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <BookingLayout>
      <div className={styles.pageContent}>
        <span className={styles.pageTitle}>Online Booking App</span>
        <div className={styles.pageSectionContainer}>
          {showConfirmation ? (
            <ConfirmationScreen />
          ) : showForm ? (
            <PatientInformationForm goBack={hidePatientForm} callback={handleSubmit} />
          ) : (
            <BookingApp callback={showPatientForm} />
          )}
        </div>
        <div className={styles.footerContainer}>
          <DearDocFooter />
        </div>
      </div>
    </BookingLayout>
  );
};
