import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.css';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  isActive?: boolean;
  type?: 'button' | 'submit';
  onClick?: () => void;
};

export const Button = ({
  children,
  className,
  onClick,
  isActive = true,
  type = 'button',
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(styles.btn, isActive ? styles.btnActive : '', className)}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};
