import { create, type StateCreator } from 'zustand';

import { logger } from '@/store/logger';
import { DearDocConnection, DearDocFreeSlots, DearDocProvider } from '@/types/api.types';

interface PracticeState {
  connection: DearDocConnection | null;
  providers: DearDocProvider[];
  freeSlots: DearDocFreeSlots | null;
}

export type PracticeStore = PracticeState & {
  setConnection: (connection: DearDocConnection | null) => void;
  setProviders: (providers: DearDocProvider[]) => void;
  setFreeSlots: (freeSlots: DearDocFreeSlots | null) => void;
};

export const initialState: Pick<PracticeStore, keyof PracticeState> = {
  connection: null,
  providers: [],
  freeSlots: null,
};

const createProvidersSlice: StateCreator<PracticeStore> = (set) => ({
  ...initialState,
  setConnection: (connection: DearDocConnection | null) => {
    set({ connection });
  },
  setProviders: (providers: DearDocProvider[]) => {
    set({ providers });
  },
  setFreeSlots: (freeSlots: DearDocFreeSlots | null) => {
    set({ freeSlots });
  },
});

export const usePracticeStore = create<PracticeStore>()(
  logger<PracticeStore>(createProvidersSlice, 'ProvidersStore'),
);
