import { create, type StateCreator } from 'zustand';

import { CreateAppointmentResponse } from '@/services/createAppointment';
import { DearDocProvider } from '@/types/api.types';
import { MeetingSlot } from '@/types/meetings.types';

import { logger } from './logger';

interface BookingState {
  provider: DearDocProvider | null;
  appointmentType: string | null;
  operatories: string[];
  slot: MeetingSlot | null;
  appointment: (Omit<BookingState, 'appointment'> & CreateAppointmentResponse) | null;
}

export type BookingStore = BookingState & {
  setProvider: (id: string, providers: DearDocProvider[]) => void;
  setAppointmentType: (appointmentType: string | null, operatories: string[]) => void;
  setSlot: (meeting: MeetingSlot | null) => void;
  onAppointmentCreated: (appointment: CreateAppointmentResponse) => void;
  clearSelection: () => void;
};

export const initialState: Pick<BookingStore, keyof BookingState> = {
  provider: null,
  appointmentType: null,
  operatories: [],
  slot: null,
  appointment: null,
};

const createBookingSlice: StateCreator<BookingStore> = (set, get) => ({
  ...initialState,
  setProvider: (id: string, providers: DearDocProvider[]) => {
    if (!id || id.length < 1) {
      return;
    }
    const provider = providers.find((provider) => provider.id === id) ?? null;
    const providerAppointments = provider?.appointment_types;
    const appointmentType =
      Number(providerAppointments?.length) < 2
        ? providerAppointments?.[0]?.id ?? ''
        : get().appointmentType;

    set({
      ...initialState,
      provider,
      appointmentType,
    });
  },
  setAppointmentType: (appointmentType: string | null, operatories: string[]) => {
    set({
      appointmentType,
      operatories,
      slot: null,
    });
  },
  setSlot: (slot: MeetingSlot | null) => {
    set({ slot });
  },
  onAppointmentCreated: (appointment: CreateAppointmentResponse) => {
    set((state) => ({
      ...initialState,
      appointment: {
        provider: state.provider,
        appointmentType: state.appointmentType,
        operatories: state.operatories,
        slot: state.slot,
        ...appointment,
      },
    }));
  },
  clearSelection: () => {
    set((state) => ({
      ...initialState,
      appointment: state.appointment,
    }));
  },
});

export const useBookingStore = create<BookingStore>()(
  logger<BookingStore>(createBookingSlice, 'BookingStore'),
);
