export const validateFullName = (input: string): boolean => {
  const words = input.trim().split(/\s+/);
  const sanitizedWords = words.map((word) => word.replace(/[^a-zA-Z]/g, ''));
  const filteredWords = sanitizedWords.filter((word) => word.length > 0);
  const isValid = filteredWords.length >= 2;
  return isValid;
};

export const validatePhone = (input: string): boolean => {
  const numRegex = /^[0-9]+$/;
  const isValid = numRegex.test(input);
  return isValid;
};
