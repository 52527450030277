import styles from './DearDocFooter.module.css';

const APP_URL = import.meta.env.VITE_BOOKING_APP_URL ?? '';

export const DearDocFooter = () => {
  return (
    <div className={styles.footerContainer}>
      <span>
        Powered by&nbsp;
        <a
          href={`https://www.getdeardoc.com/?utm_source=${APP_URL}`}
          className={styles.footerLogoLink}
          target="_blank"
        >
          <img
            className={styles.footerLogoImg}
            src="https://cdn.prod.website-files.com/620379602f832e18f32e82b9/62037ff026ae700d77898380_logo.svg"
          />
        </a>
      </span>
    </div>
  );
};
