import { useEffect, useState } from 'react';

import { BackButton } from '@/components/BackButton';
import { Loader } from '@/components/Loader';
import { useFreeSlots } from '@/hooks/useFreeSlots';
import { AppointmentTypeSelector } from '@/modules/AppointmentTypeSelector';
import { DoctorFreeSlots } from '@/modules/DoctorFreeSlots';
import { DoctorSelector } from '@/modules/DoctorSelector';
import { useBookingStore } from '@/store/useBookingStore';
import { usePracticeStore } from '@/store/usePracticeStore';

import styles from './BookingApp.module.css';

export const BookingApp = ({ callback }: { callback?: () => void }) => {
  const { isFetching: isFetchingFreeSlots, freeSlots } = useFreeSlots();
  const { provider, setAppointmentType, clearSelection } = useBookingStore();
  const { providers } = usePracticeStore();
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showAppointmentTypes, setShowAppointmentTypes] = useState(false);

  useEffect(() => {
    setShowTimePicker(!isFetchingFreeSlots && !!freeSlots);
  }, [isFetchingFreeSlots, freeSlots]);

  useEffect(() => {
    setShowAppointmentTypes(!!provider);
  }, [provider]);

  const hasProviders = providers.length > 0;

  if (isFetchingFreeSlots) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (!hasProviders) {
    return <p className={styles.noResults}>No results found</p>;
  }

  const hideAppointmentTypes = () => {
    setShowAppointmentTypes(false);
    clearSelection();
  };

  const closeSlots = () => {
    setShowTimePicker(false);
    if (Number(provider?.appointment_types?.length) < 2) {
      clearSelection();
    } else {
      setAppointmentType(null, []);
    }
  };

  const handleBack = () => {
    if (showAppointmentTypes) {
      hideAppointmentTypes();
    } else if (showTimePicker) {
      closeSlots();
    } else {
      clearSelection();
    }
  };

  return (
    <>
      {showTimePicker ? (
        <section className={styles.datetimeSelectorContainer}>
          <DoctorFreeSlots goBack={closeSlots} callback={callback} />
        </section>
      ) : (
        <section className={styles.container}>
          {showAppointmentTypes ? <AppointmentTypeSelector /> : <DoctorSelector />}
          {showAppointmentTypes && (
            <div className={styles.backBtnContainer}>
              <BackButton onClick={handleBack} />
            </div>
          )}
        </section>
      )}
    </>
  );
};
