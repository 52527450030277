import { getDearDocConnection } from '@/lib/api';
import { getLocationIdFromUrl } from '@/lib/helpers/urlParams';
import { DearDocConnection } from '@/types/api.types';

type FetchConnectionResponse = Promise<{
  error?: string | null;
  connection: DearDocConnection | null;
}>;

export const fetchConnection = async (): FetchConnectionResponse => {
  try {
    const locationId = getLocationIdFromUrl();
    if (!locationId) {
      return {
        error: 'Missing or invalid location, please provide a valid ID in the URL',
        connection: null,
      };
    }
    const res = await getDearDocConnection(locationId);

    return { connection: res.connection, error: null };
  } catch (e) {
    console.error(e);

    return {
      error: 'Sorry, error occurred fetching the connection data',
      connection: null,
    };
  }
};
