import { BookingLayout } from '@/modules/BookingLayout';

import styles from './NotFoundPage.module.css';

export const NotFoundPage = () => {
  return (
    <BookingLayout>
      <div className={styles.notFoundContainer}>
        <span className={styles.notFoundCode}>404</span>
        <p className={styles.notFoundTitle}>Page not found</p>
      </div>
    </BookingLayout>
  );
};
